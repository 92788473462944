var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('v-card',[_c('Header',{attrs:{"title":"Recently Created Events"}}),(_vm.events)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.events,"items-per-page":25},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'eventmanagerView', params: {id: item.id}}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.license",fn:function(ref){
var item = ref.item;
return [(item.id.startsWith('trial-'))?_c('v-chip',{attrs:{"outlined":"","small":""}},[_vm._v("TRIAL")]):_c('span',[_vm._v(" "+_vm._s(item.license)+" "),(item.seats)?_c('span',{staticClass:"text-muted ml-2"},[_vm._v("("+_vm._s(item.seats)+")")]):_vm._e()])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),(item.remarks)?_c('v-icon',{attrs:{"x-small":"","title":item.remarks}},[_vm._v("fa fa-comment")]):_vm._e()]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.created_on,'L LT'))+" ")]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.from,'L'))+" ")]}},{key:"item.till",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.till,'L'))+" ")]}},{key:"item.flags",fn:function(ref){
var item = ref.item;
return [(item.has_slack)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Slack Integration Enabled"}},[_vm._v("fab fa-slack")]):_vm._e(),(item.has_teams)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Microsoft Teams Integration Enabled"}},[_vm._v("fa fa-users")]):_vm._e()]}}],null,false,3648295820)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }